import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom'; // Removed Router here
import HomePage from './components/HomePage/Home';
import LoginPage from './components/login/login';
import SignUpPage from './components/signup/signup';
import ResetPasswordPage from './components/resetPassword/ResetPassword';
import ForgetPassword from './components/forgetPassword/ForgetPassword';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Topnavbar from './components/TopNavbar/Topnavbar';
import Vendordetails from './components/Vendordetails/Vendordetails';
import { ToastContainer } from 'react-toastify'; // Import Toast components
import Alltender from './components/AllVendors/Alltenders';
import Createtendor from './components/CreateTendor/createtendor';
import Tenderdetails from './components/Tenderdetails/Tenderdetails';
import VendorProfile from './components/VendorProfile/VendorProfile';
import Vendorprofile from './components/Vendordetails/Vendordetails';
import allCategoriesData from './components/data/sampleVendorDetails.json';
import EventorgProfile from './components/EventOrgProfile/EventorgProfile';
import Home from './components/landingpages/home/Home';
function App() {
  const location = useLocation(); // Now works as expected

  return (
    <>
      <Topnavbar />
      {/* Conditionally render Navbar */}
      {location.pathname !== '/' && <Navbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/vendorFinder" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route exact path="/reset-password/:token" element={<ResetPasswordPage />} />
        <Route path="/forgotPassword" element={<ForgetPassword />} />
        <Route path="/vendordetails" element={<Vendordetails />} />
        <Route path="/alltenders" element={<Alltender />} />
        <Route path="/createtender" element={<Createtendor />} />
        <Route path="/tender/:id" element={<Tenderdetails />} />
        <Route path="/vendorprofile" element={<VendorProfile />} />
        <Route path="/eventorgprofile" element={<EventorgProfile />} />

        {/* Dynamic Routes for Vendors */}
        {allCategoriesData.vendors.categories.map((category, i) =>
          category.types.map((vendor, j) => (
            <Route
              key={`${i}-${j}`}
              path={`/vendorprofile/${encodeURIComponent(vendor.name)}`}
              element={<Vendorprofile vendorData={vendor} />}
            />
          ))
        )}
      </Routes>
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;

