import React from 'react'
import './Comment.css'
import j3 from './img/j3.png'
import girl from './img/girl.png'

const Comment = () => {
  return (
    <div>
       <div className="comment-container">
            <div className="comment">
                <div className="comment-header">
                    <img src={j3} alt="Reviewer" className="profile-pic" />
                    <div className="comment-info">
                        <h3 className="comment-name">Commenter Name</h3>
                        <span className="rating">⭐ 4.5</span>
                        <span className="comment-time">Reviewed 1 month ago</span>
                    </div>
                </div>
                <p className="comment-text">This is a sample comment about the product or service being reviewed.</p>
            </div>
            <div className="comment">
                <div className="comment-header">
                    <img src={girl} alt="Reviewer" className="profile-pic" />
                    <div className="comment-info">
                        <h3 className="comment-name">Commenter Name</h3>
                        <span className="rating">⭐ 4.5</span>
                        <span className="comment-time">Reviewed 1 month ago</span>
                    </div>
                </div>
                <p className="comment-text">This is another sample comment about the product or service being reviewed.</p>
            </div>
            <div className="comment">
                <div className="comment-header">
                    <img src={girl} alt="Reviewer" className="profile-pic" />
                    <div className="comment-info">
                        <h3 className="comment-name">Commenter Name</h3>
                        <span className="rating">⭐ 4.5</span>
                        <span className="comment-time">Reviewed 1 month ago</span>
                    </div>
                </div>
                <p className="comment-text">This is yet another sample comment about the product or service being reviewed.</p>
            </div>
            <div className="vendor-view-more1">
            <button>View more</button>
          </div>
        </div>
    </div>
  )
}

export default Comment
