import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <>
            {/* <div className='footerSection'>
            <div className="main d-flex">
                <div className="  footer-width-25">
                    <img className='footerlogo' src="./assets/images/logo.png" alt="" />
                    <h3 className='footertext'>Manages all your renting
                        operations  efficiently so that you
                        can focus on growing your brand,
                        like a real boss!</h3>
                </div>
                <div className=" footer-width-25">
                    <h2 className='footercompany'>Company</h2>
                    <h3><Link className='footerlink' href="">Home</Link></h3>
                    <h3><Link className='footerlink' href="">Pricing</Link></h3>
                    <h3><Link className='footerlink' href="">Services</Link></h3>
                </div>
                <div className="footercontact footer-width-25">
                <h2 className='footercompany'>Contact</h2>
                    <h3 className='footerlink' >renter@gmail.com</h3>
                    <h3><Link className='footerlink' href="">+91 94133-3380</Link></h3>
                    <h3><Link className='footerlink' href="">Services</Link></h3>
                </div>
                <div className="footerfolowus footer-width-25">
                <h2 className='footercompany'>Follow us on</h2>
                    <h3 className='footerlink' >renter@gmail.com</h3>
                    <h3><Link className='footerlink' href="">+91 94133-3380</Link></h3>
                    <h3><Link className='footerlink' href="">Services</Link></h3>
                </div>
            </div>
            <div>
    
</div>

        </div> */}
            <div className="footer-container ">
                <div className="footer-logo">
                    <img src="./assets/images/logo.png" alt="Logo" />
                </div>

                <div className="footer-social-icons">
                    <Link to="#"><i className="fab fa-facebook-f"></i></Link>
                    <Link to="#"><i className="fab fa-twitter"></i></Link>
                    <Link to="#"><i className="fab fa-linkedin-in"></i></Link>
                    <Link to="#"><i className="fab fa-instagram"></i></Link>
                </div>

                <div className="footer-navigation">
                    <Link to="/">Home</Link>
                    <span>|</span>
                    <Link to="/tender">Tender</Link>
                    <span>|</span>
                    <Link to="/contact">Contact</Link>
                    <span>|</span>
                    <Link to="/about">About</Link>
                </div>

                <div className="footer-description">
                    <p>Manages all your renting
                        operations  efficiently so that you
                        can focus on growing your brand,
                        like a real boss!</p>
                    {/* <p>FAR paratyp i muvåning, och pesask vyfisa. Viktiga poddradio har un mad och inde.</p> */}
                </div>

                <div className="footer-credit">
                    <p>Powered By RUPK Connect Pvt. Ltd</p>
                </div>
            </div>
        </>
    )
}

export default Footer



