import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleCreateTender } from '../../utils/api'; // Import the API call for creating a tender
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './createtendor.css';

const CreateTender = () => {
  const [formData, setFormData] = useState({
    eventName: '',
    serviceRequired: '',
    location: '',
    budget: '',
    status: '',
    expireDate: '',
    startDate: '',
    endDate: '',
    description: '',
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Check if user is logged in
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('You must be logged in to create a tender');
      navigate('/login'); // Redirect to login if not logged in
    }
  }, [navigate]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    // Convert budget to string
    const formattedData = {
      ...formData,
      budget: formData.budget.toString(),
    };
  
    try {
      const result = await handleCreateTender(formattedData);
      if (result.success) {
        toast.success('Tender created successfully!');
        setFormData({
          eventName: '',
          serviceRequired: '',
          location: '',
          budget: '',
          status: '',
          expireDate: '',
          startDate: '',
          endDate: '',
          description: '',
        });
      } else {
        console.log(result)
        toast.error( 'Failed to create tender');
      }
    } catch (error) {
      toast.error('An unexpected error occurred.');
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className='create-background'>
      <div className="create-container">
        <h1 className='create-heading'>Create Tender</h1>
        <form className="create-form" onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="eventName">Event Name</label>
              <input
                className='create-input'
                type="text"
                id="eventName"
                value={formData.eventName}
                onChange={handleChange}
                required
              />
            </div>
            {/* <div className="form-group">
              <label htmlFor="serviceRequired">Service Required</label>
              <input
                className='create-input'
                type="text"
                id="serviceRequired"
                value={formData.serviceRequired}
                onChange={handleChange}
                required
              />
            </div> */}
            <div className="form-group">
              <label htmlFor="location">City</label>
              <input
                className='create-input'
                type="text"
                id="location"
                value={formData.location}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="budget">Budget</label>
              <input
                className='create-input'
                type="number"
                id="budget"
                value={formData.budget}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="status">Status</label>
              <input
                className='create-input'
                type="text"
                id="status"
                value={formData.status}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="expireDate">Expire Date</label>
              <input
                className='create-input'
                type="date"
                id="expireDate"
                value={formData.expireDate}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="startDate">Start Date</label>
              <input
                className='create-input'
                type="date"
                id="startDate"
                value={formData.startDate}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="endDate">End Date</label>
              <input
                className='create-input'
                type="date"
                id="endDate"
                value={formData.endDate}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <textarea
                className='create-input'
                id="description"
                value={formData.description}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="button-container">
            <button type="submit" disabled={loading} className='createTenderButton'>
              {loading ? 'Creating...' : 'Create Tender'}
            </button>
          </div>
        </form>
      </div>
      <ToastContainer position="top-right" autoClose={5000} />
    </div>
  );
};

export default CreateTender;
