import React from 'react'
import './Home.css'
// import City from './CityCard/City'
import Vendorcard from './VendorCard/Vendorcard'

const Home = () => {
  return (
    <div>
      <div className="d-flex justify-center">
      <div class="search-container ">
        {/* <div class="left-text">
            <p><strong> Search Jaipur vendors Houses</strong><br/></p>
        </div> */}
        {/* <div class="right-search">
            <input type="text" placeholder="Jaipur  "/>
        </div>
        <div class="right-search">
            <input type="text" placeholder="Search  "/>
            <button type="button">🔍</button>
        </div> */}
        {/* check */}

{/* <div className="mt-15">
  <div className="search-bar">
    <div className="search-dropdown">
      <select id="city-select">
        <option value="jaipur">Jaipur</option>
        <option value="delhi">Delhi</option>
        <option value="mumbai">Mumbai</option>
        <option value="bangalore">Bangalore</option>
      </select>
    </div>
    <input
      type="text"
      className="search-input"
      placeholder="Search Vendor"
    />
  </div>

  
</div> */}

    </div>

      </div>
      {/* <City/> */}
      <Vendorcard/>
    </div>
  )
}

export default Home
