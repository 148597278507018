// Spinner.js
import React from 'react';
import './Spinner.css'; // Import your CSS for styling the spinner

const Spinner = () => {
  return (
    <div className="spinner">
      {/* You can use a simple div or an animated SVG for a spinner */}
      <div className="loading"></div>
    </div>
  );
};

export default Spinner;
