import axios from 'axios';

// Axios instance with interceptors for token management
const api = axios.create({
  baseURL: 'https://rentalpe-server.codewithabhinav.online/api', // Update with your backend URL
  // baseURL: 'http://localhost:8001/api', // Update with your backend URL
  
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request Interceptor: Attach token to every request
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptor: Handle token expiration or unauthorized access globally
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized, logging out...');
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

// API Helper Function to handle requests and errors
const handleRequest = async (request) => {
  try {
    const response = await request;
    return response.data;
  } catch (error) {
    console.error('API Error:', error.response ? error.response.data : error.message);
    throw error.response ? error.response.data : { message: error.message };
  }
};

// API Call Handler
const handleApiCall = async (apiFunction, params = null) => {
  try {
    const result = params ? await apiFunction(params) : await apiFunction();
    console.log(result)
    return { success: true, data: result };
  } catch (error) {
    console.error('API call error:', error);
    return { success: false, msg: error.msg || 'An error occurred' };
  }
};

// Authentication APIs
export const login = (credentials) => handleRequest(api.post('/auth/login', credentials));
export const signUp = (userData) => handleRequest(api.post('/auth/signup', userData));
export const forgetPassword = (email) => handleRequest(api.post('/auth/forgot-password', { email }));
export const resetPassword = (token, password) => handleRequest(api.post(`/auth/reset-password/${token}`, { password }));

// Logout API
export const logout = () => {
  // Make an optional call to the server's logout endpoint if needed
  return handleRequest(api.post('/auth/logout')).finally(() => {
    // Clear token from localStorage
    localStorage.removeItem('token');
    // Redirect to login or home page after logout
    window.location.href = '/login';
  });
};


// Tenders APIs
export const createTender = (tenderData) => handleRequest(api.post('/tenders/tender', tenderData));
export const getOpenTenders = () => handleRequest(api.get('/tenders/tenders'));
// export const getAllTenders = () => handleRequest(api.get('/tenders/Alltenders'));
export const updateTender = (id, tenderData) => handleRequest(api.put(`/tenders/${id}`, tenderData));
export const deleteTender = (id) => handleRequest(api.delete(`/tenders/${id}`));
export const getUserTenders = () => handleRequest(api.get(`/tenders`));
// Get Tender by ID API
export const getTenderById = (id) => handleRequest(api.get(`/tenders/${id}`));



// User API
export const getUserById = () => handleRequest(api.get(`/user/profile`));
export const updateUser = (userData) => handleRequest(api.put(`/user/update`, userData)); // New API for updating user



// Bids APIs
export const submitBid = (tenderId, bidData) => handleRequest(api.post(`/bids/${tenderId}`, bidData));
export const getBidsForTender = (tenderId) => handleRequest(api.get(`/bids/${tenderId}`));
export const deleteBid = (tenderId, bidId) => handleRequest(api.delete(`/bids/${tenderId}/bids/${bidId}`));

// Feedback APIs
export const submitFeedback = ( tenderId,feedbackData) => handleRequest(api.post(`/${tenderId}/feedback`, feedbackData));

// Vendor Selection API
export const selectVendor = (tenderId, bidId) => handleRequest(api.post(`/tenders/${tenderId}/select-vendor`, { bidId }));

// Vendor APIs
export const getAllVendors = () => handleRequest(api.get('/vendors'));

// Example functions for handling API calls in the frontend

// Example: Handle Login
export const handleLogin = async (credentials) => {
  try {
    const response = await api.post('/auth/login', credentials); // Ensure the endpoint is correct
    return { success: true, data: response.data };
  } catch (error) {
    console.error('Login API Error:', error.response?.data || error.message);
    return { success: false, error: error.response?.data?.msg || 'Login failed' };
  }
};


// Example: Handle Sign Up
export const handleSignUp = async (userData) => {
  return handleApiCall(signUp, userData);
};

// Example: Handle Forgot Password
export const handleForgotPassword = async (email) => {
  return handleApiCall(forgetPassword, email);
};

// Example: Handle Logout
export const handleLogout = async () => {
  return handleApiCall(logout);
};


// Example: Handle Reset Password
export const handleResetPassword = async (token, password) => {
  return handleApiCall(resetPassword, { token, password });
};

// Example: Handle Get User By ID
export const handleGetUserById = async () => {
  return handleApiCall(getUserById);
};
// Example: Handle User Update
export const handleUpdateUser = async (userData) => {
  return handleApiCall(updateUser, userData); // New handler for updating user
};
// Example: Handle Tender Creation
export const handleCreateTender = async (tenderData) => {
  return handleApiCall(createTender, tenderData);
};

// Example: Handle Get Open Tenders
export const handleGetOpenTenders = async () => {
  return handleApiCall(getOpenTenders);

};
// Example: Handle Get Tender by ID
export const handleGetTenderById = async (tenderId) => {
  return handleApiCall(() => getTenderById(tenderId));
};

// // Example: Handle Get All Tenders
// export const handleGetAllTenders = async () => {
//   return handleApiCall(getAllTenders);
// };

// Example: Handle Update Tender
export const handleUpdateTender = async (id, tenderData) => {
  return handleApiCall(updateTender, { id, tenderData });
};

// Example: Handle Delete Tender
export const handleDeleteTender = async (id) => {
  return handleApiCall(deleteTender, id);
};
// Example: Handle getUser Tender
export const handleGetUserTender = async (id) => {
  return handleApiCall(getUserTenders, id);
};
// Finalize Vendor API
export const finalizeVendor = (tenderId, vendorId) =>
  handleRequest(api.post(`/tenders/${tenderId}/finalize`, { vendorId }));

// Example: Handle Finalize Vendor
export const handleFinalizeVendor = async (tenderId, vendorId) => {
  return handleApiCall(() => finalizeVendor(tenderId, vendorId));
};


// Example: Handle Submit Bid
export const handleSubmitBid = async (tenderId, bidData) => {
  return handleApiCall(() => submitBid(tenderId, bidData)); // Correctly pass bidData
};

// Example: Handle Get Bids for Tender
export const handleGetBidsForTender = async (tenderId) => {
  return handleApiCall(getBidsForTender, tenderId);
};

// Example: Handle Delete Bid
export const handleDeleteBid = async (tenderId, bidId) => {
  return handleApiCall(deleteBid, { tenderId, bidId });
};
// Update Negotiation Price API
export const updateNegotiationPrice = (bidId, negotiationPrice) => 
  handleRequest(api.patch(`/bids/${bidId}/negotiation`, { negotiationPrice }));
// Example: Handle Update Negotiation Price
export const handleUpdateNegotiationPrice = async (bidId, negotiationPrice) => {
  return handleApiCall(() => updateNegotiationPrice(bidId, negotiationPrice));
};


// Example: Handle Submit Feedback
export const handleSubmitFeedback = async (tenderId, feedbackData) => {
  return handleApiCall(submitFeedback, { tenderId, feedbackData });
};

// Example: Handle Vendor Selection
export const handleSelectVendor = async (tenderId, bidId) => {
  return handleApiCall(selectVendor, { tenderId, bidId });
};

// Example: Handle Get All Vendors
export const handleFetchVendors = async () => {
  return handleApiCall(getAllVendors);
};
