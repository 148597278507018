import React, { useState } from 'react';
import { handleLogin } from '../../utils/api';
import './login.css';
import { Link } from 'react-router-dom';
import { CiMail, CiLock } from 'react-icons/ci';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'; // Import Eye icons
import Spinner from '../../components/spinner/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false); // State to toggle password visibility

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      console.log('Attempting to log in with:', email, password); // Debug credentials
      const result = await handleLogin({ email, password });
      console.log('Login API response:', result); // Debug API response
      if (result.success) {
        localStorage.setItem('token', result.data.token);
        localStorage.setItem('user', JSON.stringify(result.data.user));
  
        toast.success('Login successful! Redirecting...');
        setTimeout(() => {
          window.location.href = '/vendorFinder';
        }, 2000);
      } else {
        setError(result.error || 'Invalid credentials');
        toast.error(result.error || 'Invalid credentials');
      }
    } catch (err) {
      setError('An unexpected error occurred.');
      toast.error('An unexpected error occurred.');
      console.error('Unexpected Error:', err); // Debug unexpected errors
    } finally {
      setLoading(false);
    }
  };
  


  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  return (
    <div className="">
      <div className="signup">
        <div className="leftsignup">
          <img src="./assets/images/login.png" alt="Login illustration" />
        </div>
        <div className="rightSignup">
          <div className="signUpTitle">Login</div>
          <form onSubmit={handleLoginSubmit}>
            <div className="inputdiv">
              <CiMail className="inputicon" />
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Email"
                required
              />
            </div>
            <div className="inputdiv">
              <CiLock className="inputicon" />
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={passwordVisible ? 'text' : 'password'} // Toggle input type
                placeholder="Password"
                required
              />
              {passwordVisible ? (
                <AiOutlineEye
                  className="eye-icon"
                  onClick={togglePasswordVisibility}
                />
              ) : (
                <AiOutlineEyeInvisible
                  className="eye-icon"
                  onClick={togglePasswordVisibility}
                />
              )}
            </div>
            <div className="bootomsign">
              <button
                className="submitButtonForSignup"
                type="submit"
                disabled={loading}
              >
                {loading ? 'Logging in...' : 'Login'}
              </button>
              <div className="bootomlineSignup">
                Do not have an account?{' '}
                <Link className="loginbutton" to="/signup">
                  <span>SignUp</span>
                </Link>
              </div>
            </div>
            {loading && <Spinner />}
          </form>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Login;
